<template>
  <AntTimePicker
    :value="props.value || ''"
    @update:value="handleUpdate"
    :valueFormat="FORMAT"
    :disabled="formState?.disabled || disabled"
    format="HH:mm"
    :use12Hours="false"
    :placeholder="placeholder"
  />
</template>

<script setup lang="ts">
import AntTimePicker from 'ant-design-vue/es/time-picker'
import { Dayjs, isDayjs } from 'dayjs'
import { useFormState } from './global-state'

const formState = useFormState()

const FORMAT = 'HH:mm'

const props = defineProps<{
  value: string | null | undefined
  disabled?: boolean
  placeholder?: string
}>()

const emit = defineEmits<{
  (e: 'update:value', value: string | null): void
}>()

const handleUpdate = (value: string | Dayjs | null) => {
  emit('update:value', isDayjs(value) ? value.format(FORMAT) : value)
}
</script>

<style lang="less" scoped>
.ant-picker {
  width: 100%;
  border-radius: 6px !important;
}
</style>
